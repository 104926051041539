import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoText, DiscoTextProps } from "@disco-ui"
import Skeleton, { SkeletonProps } from "@material-ui/lab/Skeleton"
import classNames from "classnames"
import React from "react"

type DiscoUnorderedListProps = DiscoTextProps & {
  listItems: string[]
  indent?: number
  customClassNames?: { root?: string; text?: string }
}

const DiscoUnorderedList: React.FC<DiscoUnorderedListProps> = ({
  listItems,
  indent,
  customClassNames,
  ...rest
}) => {
  const classes = useStyles({
    indent,
  })

  return (
    <ul className={classNames(classes.root, customClassNames?.root)}>
      {listItems.map((text, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>
          <DiscoText className={customClassNames?.text} {...rest}>
            {text}
          </DiscoText>
        </li>
      ))}
    </ul>
  )
}

export default DiscoUnorderedList

interface StyleProps {
  indent?: number
}

const useStyles = makeUseStyles((theme) => ({
  root: (props: StyleProps) => ({
    marginLeft: theme.spacing(props.indent || 3),
    color: theme.palette.text.primary,
  }),
}))

type DiscoUnorderedListSkeletonProps = Omit<DiscoUnorderedListProps, "variant"> &
  Omit<SkeletonProps, "variant">

export const DiscoUnorderedListSkeleton: React.FC<DiscoUnorderedListSkeletonProps> = ({
  listItems,
  indent,
  customClassNames,
  ...rest
}) => {
  const classes = useStyles({ indent })
  return (
    <ul className={classNames(classes.root, customClassNames?.root)}>
      {listItems.map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>
          <Skeleton className={customClassNames?.text} {...rest} />
        </li>
      ))}
    </ul>
  )
}
