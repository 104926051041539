import { useAuthUser } from "@/core/context/AuthUserContext"
import { useSession } from "@/core/session/MembershipSessionProvider"
import PlayArrow from "@/core/ui/iconsax/bold/play.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { handleLocalStorageError } from "@/onboarding/utils/hooks/useLocalStorageState"
import Relay from "@/relay/relayUtils"
import styleIf from "@assets/style/util/styleIf"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import { DiscoButtonProps } from "@disco-ui"
import { DiscoVideoFragment$key } from "@disco-ui/video/__generated__/DiscoVideoFragment.graphql"
import { DiscoVideoTranscriptionQuery } from "@disco-ui/video/__generated__/DiscoVideoTranscriptionQuery.graphql"
import { IconButton, Theme } from "@material-ui/core"
import MuxPlayerElement from "@mux/mux-player"
import MuxPlayer from "@mux/mux-player-react"
import "@mux/mux-player/themes/classic"
import useDebounce from "@utils/hook/useDebounce"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import localforage from "localforage"
import {
  createElement,
  FC,
  forwardRef,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react"
import { graphql, useFragment } from "react-relay"
import { v5 as uuidv5 } from "uuid"

const COMPLETE_PERCENT = 0.98 // for the purposes of resuming, what % is considered complete
const UUID_NAMESPACE = "d15c0a64-40d5-491e-99b0-da01ff1f1337"

export type DiscoVideoProgressProps = {
  played: number
  playedSeconds: number
  loaded: number
  loadedSeconds: number
}

export type DiscoVideoProps = {
  className?: string
  src?: string | null
  poster?: string | null
  radius?: keyof Theme["measure"]["borderRadius"]
  maxWidth?: string | number
  maxHeight?: string | number
  /** A callback that gets called, AT SPECIFIC INTERVALS CALCULATED, as the video is played */
  onVideoTimeUpdate?: (seconds: number, percent: number) => void
  onCuePointChange?: (cuePoint: CuePoint | undefined) => void
  playButton?: {
    hidden?: boolean
    width?: number
    height?: number
    size?: "small" | "medium"
    component?: FC<DiscoButtonProps>
  }
  /* If true, the video player is view-only */
  disablePlay?: boolean
  actionButtons?: ReactNode
  stackActions?: boolean
  assetKey?: DiscoVideoFragment$key | null
  fullWidth?: boolean
  autoplay?: boolean
  isGridItem?: boolean
} & TestIDProps

export type CuePoint = {
  time: number
  value: string
}

const DiscoVideo = forwardRef<MuxPlayerElement, DiscoVideoProps>(
  (
    {
      className,
      src,
      poster,
      radius,
      maxWidth,
      maxHeight = "440px", // Make this the default so the video doesn't take up too much of the page
      onVideoTimeUpdate,
      onCuePointChange,
      playButton,
      disablePlay,
      actionButtons,
      testid = "DiscoVideo",
      stackActions = false,
      assetKey,
      fullWidth,
      autoplay = false,
      isGridItem = false,
    },
    ref
  ) => {
    const overlayShowOnHoverClasses = useShowOnHoverStyles()
    const { authUser } = useAuthUser()
    const resumeKey =
      src && authUser
        ? `DiscoVideo-resume-${uuidv5(src + Relay.rawId(authUser.id), UUID_NAMESPACE)}`
        : undefined
    /** The last video progress recorded in seconds */
    const lastIntervalRecordedRef = useRef<number | null>(null)
    const [hasNeverPlayed, setHasNeverPlayed] = useState<boolean>(true)
    const [isPlaying, setIsPlaying] = useState<boolean>(false)

    const { pauseInactivityCountdown, resumeInactivityCountdown } = useSession()

    // Pause the inactivity countdown when the video is playing (for membership session tracking)
    useEffect(() => {
      if (hasNeverPlayed) return

      if (isPlaying) {
        pauseInactivityCountdown()
      } else {
        resumeInactivityCountdown()
      }

      // Make sure we resume activity if the video unmounts
      return () => {
        resumeInactivityCountdown()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPlaying])

    const asset = useFragment<DiscoVideoFragment$key>(
      graphql`
        fragment DiscoVideoFragment on Asset {
          id
          url
          name
          muxAssetPlaybackId
          aspectRatio
          videoDuration
        }
      `,
      assetKey ?? null
    )

    const backgroundQuery = Relay.useBackgroundQuery<DiscoVideoTranscriptionQuery>(
      graphql`
        query DiscoVideoTranscriptionQuery($id: ID!) {
          node(id: $id) {
            __typename
            ... on Asset {
              transcription {
                chapters {
                  startTime
                  title
                }
                segments {
                  start
                  end
                  text
                }
              }
            }
          }
        }
      `,
      { id: asset?.id ?? "" }
    )

    const a = Relay.narrowNodeType(backgroundQuery.node, "Asset")
    const transcription = a?.transcription

    const muxPlayerRef = useRef<MuxPlayerElement | null>(null)

    // Assign the forwarded ref to the muxPlayerRef (if provided)
    useImperativeHandle<MuxPlayerElement | null, MuxPlayerElement | null>(
      ref,
      () => muxPlayerRef.current
    )

    const debouncedResetLastIntervalRecorded = useDebounce(
      resetLastIntervalRecorded,
      5000
    )

    /** Watch progress intervals (seconds) */
    const watchProgressIntervals = useMemo(() => {
      if (!asset?.videoDuration) return []
      const duration = asset.videoDuration

      // Work out how many seconds each interval should be seperated by
      // (intervals will be no less than 5 seconds, no more than 1 minute, otherwise every 5%)
      const intervalSize = Math.max(5, Math.min(60, duration * 0.05))
      // Calculate how many intervals are needed to cover the video,
      // given the length of each interval
      const numIntervals = Math.floor(duration / intervalSize) - 1
      // Create the array of intervals
      const intervals =
        numIntervals > 0
          ? Array(numIntervals)
              .fill(null)
              .map((_, i) => (i + 1) * intervalSize)
          : []

      // Make sure we always record progress once at the 95% mark to
      // prevent edge cases where the next interval is 98% but we don't record watch progress
      // and therefore even if they got to 96%, it would be considered incomplete
      if (intervals[intervals.length - 1] < Math.ceil(duration * 0.95)) {
        intervals[intervals.length - 1] = Math.ceil(duration * 0.95)
      }

      intervals.unshift(0) // Add interval for begining of video
      intervals.push(duration) // Add interval for end of video

      return intervals
    }, [asset?.videoDuration])

    useEffect(() => {
      if (!muxPlayerRef.current) return

      const muxPlayer = muxPlayerRef.current
      // muxPlayer.media?.nativeEl.setAttribute("playsinline", "")
      muxPlayer.addEventListener("click", stopPropagationHandler)
      muxPlayer.addEventListener("keydown", stopPropagationHandler)

      return () => {
        muxPlayer.removeEventListener("click", stopPropagationHandler)
        muxPlayer.removeEventListener("keydown", stopPropagationHandler)
      }
    }, [])

    const classes = useStyles({
      radius,
      maxWidth,
      maxHeight,
      hasNeverPlayed,
      playButton: { width: playButton?.width, height: playButton?.height },
      stackActions,
      fullWidth,
      poster: poster || undefined,
      aspectRatio: asset?.aspectRatio || undefined,
      isGridItem,
    })

    return (
      /**
       *   Since `mux-player` is not a native interactive element, DnD doesn't block dragging
       *    on the video element by default. This breaks media controls when
       *    the player is inside a <Draggable> component. To fix this, wrap the video player in contenteditable element
       *    so that DnD can block dragging on the video player
       *    https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/draggable.md#interactive-child-elements-within-a-draggable-
       */
      <div
        contentEditable={true}
        className={classNames(classes.videoWrapper, className, {
          /** Make the overlay container showable only when not playing */
          [overlayShowOnHoverClasses.hoverable]: !isPlaying && !hasNeverPlayed,
        })}
        data-testid={`${testid}.video-wrapper`}
        // Make it uninteractable
        onBeforeInput={(e) => e.preventDefault()}
      >
        <MuxPlayer
          ref={muxPlayerRef}
          // MuxPlayer prioritizes playbackId over src so it is safe to
          // pass both here
          src={asset?.url || src}
          playbackId={asset?.muxAssetPlaybackId ?? undefined}
          metadata={
            asset
              ? {
                  video_id: asset.id,
                  video_title: asset.name,
                  viewer_user_id: authUser?.id,
                }
              : undefined
          }
          theme={"classic"}
          onTimeUpdate={handleTimeUpdate}
          streamType={"on-demand"}
          maxResolution={"720p"}
          autoPlay={autoplay}
          className={classNames(classes.discoVideoRoot)}
          onLoadedData={handleOnReady}
          onCuePointChange={({ detail }) => onCuePointChange?.(detail)}
          playbackRates={[0.5, 1, 1.25, 1.5, 2]}
          poster={poster ?? undefined}
          preload={"metadata"}
          onPlay={handleOnPlay}
          onPause={handleOnPause}
          onSeeked={handleOnSeeked}
        />
        {/** Don't show the poster if the video has played */}
        {hasNeverPlayed && poster && <div className={classes.poster} />}
        <div
          className={classNames(classes.actionButtonsOverlay, {
            /** Make the overlay container showable only when not playing */
            [overlayShowOnHoverClasses.showable]: !isPlaying && !hasNeverPlayed,
          })}
        >
          {/** Only show action buttons when video not playing */}
          {!isPlaying && actionButtons && (
            <div className={classes.actionButtons}>{actionButtons}</div>
          )}
        </div>
        {/** Main play button when video has not been played yet */}
        {hasNeverPlayed && !playButton?.hidden && (
          <div
            className={classes.playButtonBackground}
            onClick={(e) => {
              if (disablePlay) return
              preventPropagationHandler(e)
            }}
            onKeyDown={preventPropagationHandler}
            role={"button"}
            tabIndex={-1}
          >
            <span className={classes.playButtonContainer}>
              {playButton?.component ? (
                createElement(playButton?.component, {
                  disabled: disablePlay,
                  onClick: (event: React.MouseEvent<HTMLButtonElement>) =>
                    preventPropagationHandler(event),
                  testid: `${testid}.play-button`,
                })
              ) : (
                <IconButton
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                    preventPropagationHandler(event)
                  }
                  className={classes.playButton}
                  disabled={disablePlay}
                  data-testid={`${testid}.play-button`}
                  size={playButton?.size}
                >
                  <PlayArrow height={24} width={24} />
                </IconButton>
              )}
            </span>
          </div>
        )}
      </div>
    )

    // Function to handle video play
    function playVideo() {
      if (disablePlay) return
      if (!muxPlayerRef.current) return

      muxPlayerRef.current.play()
      resetLastIntervalRecorded()
    }
    /*
     * stop click events from bubbling to parents of the video component,
     * since when a user attempts to play video, we don't want other click
     * events to be triggered that would
     * ex: open a drawer, or navigate them away from the video
     */
    // Generic function to stop event propagation and call playVideo
    function preventPropagationHandler(
      event:
        | MouseEvent<HTMLButtonElement>
        | KeyboardEvent<HTMLButtonElement>
        | MouseEvent<HTMLDivElement>
        | KeyboardEvent<HTMLDivElement>
    ) {
      event.stopPropagation()
      playVideo()
    }

    function stopPropagationHandler(event: Event) {
      event.stopPropagation()
    }

    function addChaptersToVideo() {
      if (!transcription?.chapters?.length) return
      if (!muxPlayerRef.current) return

      const { chapters } = transcription
      const muxChapters: { startTime: number; endTime: number; value: string }[] = []

      for (const [idx, chapter] of chapters.entries()) {
        const { startTime } = chapter
        const endTime = chapters[idx + 1]?.startTime || muxPlayerRef.current.duration
        const value = chapter.title
        muxChapters.push({ startTime, endTime, value })
      }

      muxPlayerRef.current.addChapters(muxChapters)
    }

    function addCuePointsToVideo() {
      if (!transcription?.segments?.length) return
      if (!muxPlayerRef.current) return

      const { segments } = transcription

      const muxCuePoints: { time: number; value: string }[] = []

      for (const segment of segments) {
        const { start, text } = segment
        muxCuePoints.push({ time: start, value: text })
      }
      muxPlayerRef.current.addCuePoints(muxCuePoints)
    }

    function handleTimeUpdate(e: Event) {
      const el = e.target as MuxPlayerElement
      const { duration, currentTime } = el
      if (resumeKey) {
        const pc = currentTime / duration
        if (pc > 0.02 && pc <= COMPLETE_PERCENT) {
          // only save the progress if the user is > 2% into the video
          try {
            localforage.setItem(resumeKey, currentTime.toString())
          } catch (err) {
            handleLocalStorageError(err)
          }
        } else if (pc > COMPLETE_PERCENT) {
          // consider any watch percent > 98% to be complete
          localforage.removeItem(resumeKey)
        }
      }

      handleVideoTimeUpdate(currentTime)
    }

    function handleVideoTimeUpdate(currentTime: number) {
      if (!asset?.videoDuration) return
      if (!onVideoTimeUpdate) return
      const latestIntervalPassed = getLatestIntervalPassed(currentTime)
      if (latestIntervalPassed === undefined) return // Did not pass any intervals yet, so don't record progress

      // Check if we have passed the next interval, if we did then record progress
      if (
        lastIntervalRecordedRef.current === null ||
        lastIntervalRecordedRef.current < latestIntervalPassed
      ) {
        lastIntervalRecordedRef.current = latestIntervalPassed
        onVideoTimeUpdate(
          Math.floor(latestIntervalPassed),
          Number((latestIntervalPassed / asset.videoDuration).toFixed(10))
        )
      }
    }

    function getLatestIntervalPassed(currentTime: number): number | undefined {
      for (let i = watchProgressIntervals.length - 1; i >= 0; i--) {
        const interval = watchProgressIntervals[i]
        if (Math.round(currentTime) >= Math.round(interval)) {
          return interval
        }
      }
      return undefined
    }

    function resetLastIntervalRecorded() {
      lastIntervalRecordedRef.current = null
    }

    async function handleOnReady() {
      addChaptersToVideo()
      addCuePointsToVideo()

      if (!resumeKey) return
      if (!muxPlayerRef?.current) return

      const resumeAt = await localforage.getItem<number>(resumeKey)
      if (!resumeAt) return
      const videoDuration = muxPlayerRef.current.duration
      const pc = resumeAt / videoDuration
      // 98% watched === completed
      if (pc >= COMPLETE_PERCENT) return

      muxPlayerRef.current.currentTime = resumeAt
      lastIntervalRecordedRef.current = resumeAt
    }

    function handleOnPlay() {
      setIsPlaying(true)
      setHasNeverPlayed(false)
      resetLastIntervalRecorded()
    }

    function handleOnPause() {
      setIsPlaying(false)
    }

    function handleOnSeeked() {
      // Debounce in case they are just randomly jumping through the video
      // handlePlayerTimeUpdate will only record progress if we passed the lastIntervalRecorded
      debouncedResetLastIntervalRecorded()
    }
  }
)

type StyleProps = {
  hasNeverPlayed: boolean
  radius?: keyof Theme["measure"]["borderRadius"]
  maxWidth?: string | number
  maxHeight?: string | number
  playButton?: { width: number | undefined; height: number | undefined }
  stackActions?: boolean
  fullWidth?: boolean
  poster?: string
  aspectRatio?: string
  isGridItem?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  videoWrapper: ({ radius, fullWidth }: StyleProps) => ({
    position: "relative",
    display: "block",
    borderRadius: radius ? theme.measure.borderRadius[radius] : undefined,
    overflow: "hidden",
    width: fullWidth ? "100%" : undefined,
    // CSS part selectors for the MuxPlayer https://docs.mux.com/guides/player-customize-look-and-feel#css-parts
    // Disable the seek buttons in the controls, control buttons in the center
    "& mux-player::part(center button), mux-player::part(seek-backward button), mux-player::part(seek-forward button)":
      {
        display: "none",
      },
    "& mux-player::part(playback-rate button)": {
      // Unset the inherited styles from #editor-content-editable, which would break the spacing in the controls
      whiteSpace: "normal",
      wordbreak: "normal",
    },
    "& mux-player::part(range)": {
      // Prevent the time bar from showing up on top of other elements like modal and drawer
      zIndex: 0,
    },
  }),
  discoVideoRoot: ({ hasNeverPlayed, aspectRatio }: StyleProps) => ({
    display: "flex",
    "--media-object-fit": "contain", // Show full video with black side bars instead of cropping out
    // Hide the controls until the video is played
    ...styleIf(hasNeverPlayed, {
      "--bottom-controls": "none",
    }),
    aspectRatio: aspectRatio ? aspectRatio.replace(":", " / ") : "16 / 9",
  }),
  playButtonBackground: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
  playButtonContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
  },
  playButton: ({ playButton }: StyleProps) => ({
    backgroundColor: theme.palette.groovy.blue[400],
    border: `2px solid ${theme.palette.common.white}`,
    "& svg": {
      height: playButton ? `${playButton?.height}px` : "60px",
      width: playButton ? `${playButton?.width}px` : "60px",
      color: theme.palette.common.white,
    },
    "&:hover": {
      backgroundColor: theme.palette.groovy.blue[300],
    },
    "&:disabled": {
      backgroundColor: theme.palette.groovy.blue[400],
    },
    [theme.breakpoints.down("sm")]: {
      height: playButton ? `${playButton?.height}px` : "24px",
      width: playButton ? `${playButton?.width}px` : "24px",
    },
  }),
  actionButtonsOverlay: ({ hasNeverPlayed, stackActions }: StyleProps) => ({
    position: "absolute",
    top: hasNeverPlayed ? "50%" : theme.spacing(1.5),
    right: hasNeverPlayed ? "50%" : theme.spacing(1.5),
    transform: hasNeverPlayed ? "translateY(-50%) translateX(50%)" : "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1.5),
    zIndex: theme.zIndex.raise2,
    ...styleIf(stackActions, {
      flexDirection: "column-reverse",
    }),
  }),
  actionButtons: ({ hasNeverPlayed, stackActions }: StyleProps) => ({
    ...styleIf(stackActions && hasNeverPlayed, {
      marginTop: theme.spacing(3.25),
      position: "absolute",
      top: "100%",
    }),
  }),
  poster: ({ poster, isGridItem }: StyleProps) => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.groovy.onDark[600],
    backgroundImage: `url(${poster})`,
    backgroundSize: isGridItem ? "cover" : "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  }),
}))

export default DiscoVideo
