import TypeformAppIcon from "@assets/disco/icons/color-logo/logo-typeform.svg"
import TypeformDarkAppIcon from "@assets/disco/icons/color-logo/logo-typeformdark.svg"
import { Spacing } from "@assets/style/appMuiTheme"
import makeSpacingStyles from "@assets/style/util/makeSpacingStyles"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import {
  DiscoIconToIconsaxMap,
  IconsaxIconKinds,
  IconsaxLinearToBoldMap,
} from "@disco-ui/icon/iconMaps"
import IconsaxIcon, { IconsaxIconProps } from "@disco-ui/icon/IconsaxIcon"
import { useTheme } from "@material-ui/core"
import classNames from "classnames"
import React from "react"

type DiscoIconKey = keyof typeof DiscoIconToIconsaxMap
export type DiscoIconKinds = DiscoIconKey

/** Exclude "string" type from ReactText and narrow for DiscoIconKinds instead */
export type DiscoIconKindsReactNodeUnion =
  | DiscoIconKinds
  | (Exclude<React.ReactNode, React.ReactChild> &
      Exclude<React.ReactChild, React.ReactText> &
      number)

type IconRotation = "90" | "180" | "270" | "-90" | "-180" | "-270"

export type DiscoIconProps = {
  icon: DiscoIconKinds | IconsaxIconKinds
  active?: boolean
  color?: string
  background?: string
  className?: string
  height?: number
  width?: number
  rotate?: IconRotation
  noShrink?: boolean
} & Spacing

function DiscoIcon({
  icon,
  active = false,
  color,
  background,
  className,
  height = 24,
  width = 24,
  margin = 0,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  padding = 0,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  rotate,
  noShrink,
}: DiscoIconProps) {
  // Pack spacing props into a single object
  const spacing = {
    margin,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    padding,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    rotate,
  }

  const spacingStyles = useSpacingStyles(spacing)
  const classes = useStyles({
    active,
    color,
    background,
    height,
    width,
    rotate,
    noShrink,
  })
  // if the icon is prefixed with `iconsax.`, we're referencing a iconsax file (or path)
  const iconsaxRegex = /iconsax\.(bold|linear)*\/?([\w-]+)/g
  const regexMatch = iconsaxRegex.exec(icon)
  // in some cases we use a bold variant when icon not active, so we need to extract the variant
  const iconsaxVariant = regexMatch?.[1] as IconsaxIconProps["variant"] | undefined
  const iconsaxName = regexMatch?.[2]
  const isIconsaxFamily = !!iconsaxName

  let iconsaxIcon = isIconsaxFamily
    ? iconsaxName
    : DiscoIconToIconsaxMap[icon as DiscoIconKey]

  // get bold variant if one exists
  const iconsaxBoldVariant =
    IconsaxLinearToBoldMap[iconsaxIcon as keyof typeof IconsaxLinearToBoldMap]
  const isBoldVariant = active && iconsaxBoldVariant

  if (isBoldVariant) iconsaxIcon = iconsaxBoldVariant

  if (!iconsaxIcon) return null

  return (
    <IconsaxIcon
      icon={iconsaxIcon}
      variant={iconsaxVariant ?? (isBoldVariant ? "bold" : "linear")}
      testid={`DiscoIcon.${isIconsaxFamily ? iconsaxName : icon}${
        active ? ".active" : ""
      }`}
      className={classNames(classes.root, spacingStyles.root, className)}
    />
  )
}

export type CustomDiscoIconProps = {
  icon: JSX.Element
  active?: boolean
  color?: string
  background?: string
  className?: string
  height?: number
  width?: number
  rotate?: IconRotation
} & Spacing

export function DiscoCustomIcon({
  icon,
  active = false,
  color,
  background,
  className,
  height = 24,
  width = 24,
  margin = 0,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  padding = 0,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  rotate,
}: CustomDiscoIconProps) {
  // Pack spacing props into a single object
  const spacing = {
    margin,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    padding,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    rotate,
  }
  const spacingStyles = useSpacingStyles(spacing)
  const classes = useStyles({ active, color, background, height, width, rotate })

  return React.cloneElement(icon, {
    "data-testid": `DiscoIcon.${icon}${active ? ".active" : ""}`,
    className: classNames(classes.root, spacingStyles.root, className),
  })
}

type StyleProps = {
  active?: boolean
  color?: string
  background?: string
  height: number
  width: number
  rotate?: IconRotation
  noShrink?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  root: (props: StyleProps) => ({
    width: props.width,
    height: props.height,
    color:
      props.color ??
      (props.active ? theme.palette.text.primary : theme.palette.constants.icon),
    ...styleIf(props.background, {
      background: props.background,
    }),
    transform: props.rotate ? `rotate(${props.rotate}deg)` : undefined,
    ...styleIf(props.noShrink, {
      flexShrink: 0,
    }),
  }),
}))

const useSpacingStyles = makeSpacingStyles()

export default DiscoIcon

export function GetTypeformAppIcon() {
  const theme = useTheme()
  const isDark = theme.palette.type === "dark"
  return isDark ? <TypeformDarkAppIcon /> : <TypeformAppIcon />
}
